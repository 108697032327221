const ColorPickerPreset = (props) => {
	const { color, selected, onPresetSelect, marginBottom } = props;

	return (
		<div
			key={color}
			className={"colorPickerPreset" + (selected ? " selected" : "") + (onPresetSelect ? " clickable" : "")}
			style={{ backgroundColor: color, marginBottom }}
			onClick={onPresetSelect ? () => onPresetSelect(color) : null}
		></div>
	);
};

export default ColorPickerPreset;
