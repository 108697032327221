import React from "react";
import FilterBox from "./filterBox";
import DisplayButtons from "./displayButtons";

const Configurator = (props) => {
	const { display, onChange, filter, onFilterUpdate } = props;

	return (
		<div className="row pt-3 mt-5">
			<div className="col-12 col-lg-3 px-0 d-flex d-lg-block justify-content-center order-3 order-lg-first">
				<FilterBox filter={filter} onFilterUpdate={onFilterUpdate} />
			</div>
			<div className="col-12 d-flex d-lg-none order-2" style={{ height: "10px" }}></div>
			<div className="col-12 col-lg-5 d-flex justify-content-center">
				<DisplayButtons display={display} onChange={onChange} />
			</div>
		</div>
	);
};

export default Configurator;
