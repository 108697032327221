import React, { Component } from "react";
import { Square, CheckSquare, QrCode } from "react-bootstrap-icons";
import ApplicationContext from "../../services/applicationContext";
import errorMessages from "../../conf/errors.json";

class TransactionGroupActions extends Component {
	static contextType = ApplicationContext;

	getButtonStyles = (disabled) => {
		return !disabled ? { cursor: "pointer" } : null;
	};

	getButtonClasses = (marginEnd, textColor, disabled, hasLicense) => {
		return "me-" + marginEnd + " " + (disabled || !hasLicense ? "text-secondary opacity-50" : textColor) + (!hasLicense ? " license-expired" : "");
	};

	render() {
		const { processed, onProcessed, onQR, disableQR, disableQRReason } = this.props;
		const hasLicense = this.context.getLicenseType();
		const buttonHint = !hasLicense ? errorMessages["license_expired"] : "";

		return (
			<div className="d-flex align-items-baseline ms-3 text-nowrap text-center" style={{ marginBottom: "2px" }}>
				{onQR && (
					<div title={disableQRReason || "QR-Code für die Überweisung erzeugen"}>
						<QrCode
							className={this.getButtonClasses(2, "text-primary", disableQR, hasLicense)}
							size={16}
							style={this.getButtonStyles(disableQR)}
							onClick={disableQR ? null : onQR}
						/>
					</div>
				)}

				{onProcessed && (
					<div title={buttonHint || "Zahlungsstatus"}>
						{processed && (
							<CheckSquare
								className={this.getButtonClasses(2, "text-primary", false, hasLicense)}
								size={16}
								style={this.getButtonStyles(false)}
							/>
						)}
						{!processed && (
							<Square
								className={this.getButtonClasses(2, "text-primary", false, hasLicense)}
								size={16}
								style={this.getButtonStyles(false)}
								onClick={hasLicense ? onProcessed : null}
							/>
						)}
					</div>
				)}
			</div>
		);
	}
}

export default TransactionGroupActions;
