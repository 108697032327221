import React, { useContext } from "react";
import ApplicationContext from "../../services/applicationContext";
import LicenseCard from "./licenseCard";
import UserCard from "./userCard";

const Profil = () => {
	const context = useContext(ApplicationContext);
	const { user } = context;

	const handleEditClick = () => {};

	return (
		<React.Fragment>
			<div className="row gx-md-5 px-4 px-sm-0">
				<div className="d-none d-lg-block col-1"></div>
				<div className="col-md-auto">
					<h1 className="mt-5">Profil-Informationen</h1>
				</div>
			</div>
			{user && (
				<div className="row gx-md-5 pb-5 px-4 px-sm-0">
					<div className="d-none d-lg-block col-1 mt-5"></div>
					<div className="col-sm-6 col-lg-5 mt-5">
						<UserCard user={user} handle={handleEditClick} />
					</div>
					<div className="col-sm-6 col-lg-5 mt-5">
						<LicenseCard
							license={user.license || user.previousLicenses[user.previousLicenses.length - 1]}
							expired={!user.hasOwnProperty("license")}
						/>
					</div>
					<div className="col-lg-1"></div>
				</div>
			)}
		</React.Fragment>
	);
};

export default Profil;
