import React from "react";
import { Link, withRouter } from "react-router-dom";
import Form from "../common/forms/form";
import Input from "../common/forms/input";
import TextArea from "../common/forms/textarea";
import Validation from "../../services/validation";
import Support from "../../services/supportService";
import { toast } from "react-toastify";
import ApplicationContext from "../../services/applicationContext";
import ResponsiveButton from "../common/forms/responsiveButton";

class ContactFormComponent extends Form {
	static contextType = ApplicationContext;
	state = {
		// @Override
		data: {
			userId: this.context.user ? this.context.user.userId : null,
			name: this.context.user ? this.context.user.name : "",
			email: this.context.user ? this.context.user.email : "",
			message: "",
		},
		errors: {},
	};

	// @Override
	getSchema = () => Validation.schemas.contactFormSchema;

	// @Override
	doSubmit = async () => {
		try {
			await Support.submitSupportContact(this.state.data);
			const forwardDestination = this.context.user ? "/app/start" : "/";
			toast.success("Deine Nachricht wurde erfolgreich übermittelt.", {
				onClose: () => this.props.history.push(forwardDestination),
			});
		} catch (ex) {
			Support.handleExpectedErrors(ex, this);
		}
	};

	// @Override
	render() {
		const { errorMessage, errors, data } = this.state;
		const { supportContactLength } = this.context.configuration;

		return (
			<div>
				<form onSubmit={this.handleSubmit}>
					<Input
						label="Name"
						name="name"
						error={errors.name}
						type="text"
						placeholder="Max Mustermann"
						value={data.name}
						onChange={this.handleChange}
					/>
					<Input
						label="E-Mail"
						name="email"
						error={errors.email}
						type="text"
						placeholder="max@mustermail.de"
						value={data.email}
						onChange={this.handleChange}
					/>
					<TextArea
						label="Nachricht"
						name="message"
						error={errors.message}
						rows={5}
						maxLength={supportContactLength}
						placeholder="Deine Nachricht..."
						value={data.message}
						onChange={this.handleChange}
					/>

					{errorMessage && (
						<div className="alert alert-danger" role="alert">
							{errorMessage}
						</div>
					)}

					<ResponsiveButton
						label="Absenden"
						color="primary"
						hint="Sendet Deine Nachricht an Mein Sparstrumpf."
						hasLicense={true}
						disabled={this.validate()}
						disabledHint="Bitte fülle zunächst das Formular aus."
					/>

					<div className="form-text mt-2">
						Wir verpflichten uns zum Schutz Deiner Daten. Details erfährst Du unter <Link to="/datenschutz">Datenschutz</Link>.
					</div>
				</form>
			</div>
		);
	}
}

const ContactForm = withRouter(ContactFormComponent);

export default ContactForm;
