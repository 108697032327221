import React from "react";
import Input from "../common/forms/input";
import Select from "../common/forms/select";
import Form from "../common/forms/form";
import CheckBox from "../common/forms/checkbox";
import Validation from "../../services/validation";
import { isSameMonth } from "../../services/dateService";
import Format from "../../services/formattingService";
import Recurring from "../../services/recurringService";
import ApplicationContext from "../../services/applicationContext";
import ResponsiveButton from "../common/forms/responsiveButton";

class RecurringForm extends Form {
	static contextType = ApplicationContext;

	transactionTypes = [
		{ value: "deposit", label: "Einzahlung" },
		{ value: "payout", label: "Auszahlung" },
	];

	intervalTypes = [
		{ value: "1", label: "monatlich" },
		{ value: "2", label: "alle 2 Monate" },
		{ value: "3", label: "quartalsweise" },
		{ value: "6", label: "halbjährlich" },
		{ value: "12", label: "jährlich" },
		{ value: "24", label: "alle 2 Jahre" },
		{ value: "36", label: "alle 3 Jahre" },
		{ value: "48", label: "alle 4 Jahre" },
	];

	getInitialBookingDate = () => {
		return !this.props.month || isSameMonth(this.props.month, new Date()) ? new Date() : this.props.month;
	};

	getInitialEndDate = () => {
		const endDate = new Date(this.getInitialBookingDate());
		endDate.setFullYear(endDate.getFullYear() + 1);
		return endDate;
	};

	state = {
		// @Override
		data: {
			type: this.props.transactionType || "",
			startDate: this.getInitialBookingDate(),
			setEndDate: false,
			endDate: this.getInitialEndDate(),
			interval: "12",
			description: "",
			includeInCMD: false,
			value: 0,
		},
		errors: {},
	};

	// @Override
	getSchema = () => Validation.schemas.recurringFormSchema;

	// @Override
	doSubmit = async () => {
		const { data } = this.state;
		const { stockingId, accountId, onSubmitted } = this.props;

		const requestData = { ...data };
		delete requestData.setEndDate;

		if (!data.setEndDate || isNaN(data.endDate.getTime())) delete requestData.endDate;
		else requestData.endDate = Format.isodate(data.endDate);

		requestData.startDate = Format.isodate(data.startDate);
		requestData.interval = Number(data.interval);
		requestData.value = Math.round(requestData.value * 100) / 100;
		if (data.type === "payout") requestData.value = requestData.value * -1;

		try {
			const recurring = await Recurring.createRecurring(accountId, stockingId, requestData);
			onSubmitted(recurring);
		} catch (ex) {
			console.log(ex);
			Recurring.handleExpectedErrors(ex, this);
		}
	};

	// @Override
	render() {
		const { errorMessage, errors, data } = this.state;
		const hasLicense = this.context.getLicenseType();

		return (
			<form onSubmit={this.handleSubmit}>
				<Select
					label="Typ"
					name="type"
					error={errors.type}
					type="text"
					value={data.type}
					options={this.transactionTypes}
					onChange={this.handleChange}
				/>

				<CheckBox
					label="Der automatischen Einzahlung zuweisen"
					name="includeInCMD"
					value={data.type === "deposit" && data.includeInCMD}
					error={errors.includeInCMD}
					disabled={data.type === "payout"}
					onChange={this.handleChange}
				/>

				<div className="row">
					<div className="col-6">
						<Input
							label="Start-Datum"
							name="startDate"
							error={errors.startDate}
							type="date"
							value={Format.isodate(data.startDate)}
							onChange={this.handleChange}
						/>
					</div>
					<div className="col-6">
						{data.setEndDate && (
							<Input
								ref={this.endDateRef}
								label="End-Datum"
								name="endDate"
								error={errors.endDate}
								type="date"
								value={Format.isodate(data.endDate)}
								onChange={this.handleChange}
							/>
						)}
						{!data.setEndDate && (
							<CheckBox
								label="Enddatum setzen"
								name="setEndDate"
								value={data.setEndDate}
								error={errors.setEndDate}
								onChange={this.handleChange}
							/>
						)}
					</div>
				</div>

				<Select
					label="Intervall"
					name="interval"
					error={errors.interval}
					value={data.interval}
					options={this.intervalTypes}
					onChange={this.handleChange}
				/>

				<Input
					label="Beschreibung"
					placeholder={data.type === "deposit" ? "z.B. Sparplan" : "z.B. Jahresbeitrag"}
					name="description"
					error={errors.description}
					type="text"
					value={data.description}
					onChange={this.handleChange}
				/>

				<Input
					label="Betrag"
					placeholder=""
					name="value"
					error={errors.value}
					type="number"
					step="0.01"
					value={data.value}
					onClick={(e) => e.currentTarget.select()}
					onChange={this.handleChange}
				/>

				{errorMessage && (
					<div className="alert alert-danger" role="alert">
						{errorMessage}
					</div>
				)}

				<ResponsiveButton
					label="Anlegen"
					color="primary"
					hint="Erstellt diese wiederkehrende Buchung im System."
					hasLicense={hasLicense}
					disabled={this.validate()}
					disabledHint="Bitte fülle zunächst das Formular aus."
				/>
			</form>
		);
	}
}

export default RecurringForm;
