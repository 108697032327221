import React, { Component } from "react";
import { toast } from "react-toastify";
import { Gear, ArrowCounterclockwise } from "react-bootstrap-icons";
import _ from "lodash";
import StockingService from "../../services/stockingService";
import Table from "../common/table";
import Format from "../../services/formattingService";
import ColorPickerPreset from "../common/forms/colorPickerPreset";
import Spinner from "../common/spinner";
import StockingForm from "../forms/stockingForm";
import ApplicationContext from "../../services/applicationContext";
import ResponsiveButton from "../common/forms/responsiveButton";

class StockingManagement extends Component {
	static contextType = ApplicationContext;

	getColumns = (table, currency) => {
		const columns = [
			{ label: "Farbe", path: "color", content: (d) => <ColorPickerPreset color={d.color || "#23ac4f"} selected={false} /> },
			{ label: "Name", path: "name" },
			{ label: "Pos.", align: "center", path: "position" },
			{ label: "Erstellt", path: "enrollmentDate", content: (d) => <React.Fragment>{Format.date(new Date(d.enrollmentDate))}</React.Fragment> },
			{ label: "Typ", path: "type", content: (d) => <React.Fragment>{d.type === "automatic" ? "Automatisch" : "Manuell"}</React.Fragment> },
			{ label: "Saldo", align: "end", path: "balance", content: (d) => (d.hasOwnProperty("balance") ? Format.money(d.balance, currency) : <Spinner />) },
		];

		if (table === "active") {
			// add further columns for the active table
			return [
				...columns,
				{
					label: "",
					path: "stockingId",
					align: "end",
					content: (d) => (
						<ResponsiveButton
							label="Bearbeiten"
							color="primary"
							hint="Öffnet das Dialogfeld zum Bearbeiten dieses Sparstrumpfes."
							hasLicense={true}
							disabled={!d.hasOwnProperty("balance")}
							disabledHint="Für diese Funktion muss noch der Saldo des Sparstrumpes abgefragt werden."
							className="me-2"
							onClick={() => this.handleEdit(d)}
						/>
					),
				},
			];
		}

		if (table === "archived") {
			// add further columns for the archived table
			return [
				...columns,
				{
					label: "",
					path: "stockingId",
					align: "end",
					content: (d) => (
						<ResponsiveButton
							label="Wiederherstellen"
							color="primary"
							hint="Stellt den archivierten Sparstrumpf wieder her, so dass er wieder normal verwendet werden kann."
							hasLicense={true}
							disabled={false}
							className="me-2"
							onClick={() => this.handleUnarchive(d.stockingId)}
						/>
					),
				},
			];
		}
	};

	getNarrowTableColumns = (table, currency) => {
		const columns = [
			{ label: "Farbe", path: "color", content: (d) => <ColorPickerPreset color={d.color} selected={false} /> },
			{
				label: "Name / Typ",
				path: "name",
				content: (d) => (
					<>
						{d.name}
						<br />
						{d.type === "automatic" ? "Automatisch" : "Manuell"}
					</>
				),
			},
			{
				label: "Erstellt / Saldo",
				path: "enrollmentDate",
				content: (d) => (
					<>
						{Format.date(new Date(d.enrollmentDate))}
						<br />
						{d.hasOwnProperty("balance") ? Format.money(d.balance, currency) : "..."}
					</>
				),
			},
		];

		if (table === "active") {
			// add further columns for the active table
			return [
				...columns,
				{
					label: "",
					path: "stockingId",
					align: "end",
					content: (d) => <Gear className="text-primary clickable" size="24" onClick={() => this.handleEdit(d)} />,
				},
			];
		}

		if (table === "archived") {
			// add further columns for the active table
			return [
				...columns,
				{
					label: "",
					path: "stockingId",
					align: "end",
					content: (d) => <ArrowCounterclockwise className="text-primary clickable" size="24" onClick={() => this.handleUnarchive(d.stockingId)} />,
				},
			];
		}
	};

	state = {
		stockings: [],
		activeColumns: [],
		archivedColumns: [],
		activeSort: { key: "position", order: "asc" },
		archivedSort: { key: "position", order: "asc" },
	};

	handleEdit = (stocking) => {
		const { account } = this.props;
		const { accountId } = account;
		const deletable = stocking.balance === 0;
		const data = { name: stocking.name, type: stocking.type, stockingId: stocking.stockingId, color: stocking.color };
		this.context.showFormModal(
			"Sparstrumpf bearbeiten",
			StockingForm,
			{ accountId, buttonLabel: "Speichern", data, deletable },
			"md",
			this.handleStockingUpdate
		);
	};

	handleStockingUpdate = (operation, stocking) => {
		this.context.hideModal();
		if (operation === "update") {
			if (stocking !== null) {
				const balance = this.state.stockings.find((s) => s.stockingId === stocking.stockingId).balance;
				const stockings = [...this.state.stockings];
				const idx = stockings.findIndex((s) => s.stockingId === stocking.stockingId);
				stockings[idx] = { ...stocking, balance };
				this.setState({ stockings });
			}
		} else if (operation === "delete") {
			const stockings = this.state.stockings.filter((s) => s.stockingId !== stocking.stockingId);
			this.setState({ stockings });
		}
	};

	handleUnarchive = async (stockingId) => {
		const { account } = this.props;
		const previousStockings = [...this.state.stockings];

		const stockings = this.state.stockings.map((s) => (s.stockingId === stockingId ? { ...s, visible: true } : s));
		this.setState({ stockings });
		try {
			await StockingService.updateStocking(account.accountId, stockingId, { visible: true });
		} catch (error) {
			console.log("update stocking error", error);
			toast.warn("Deine Änderung konnte nicht übernommen werden.");
			this.setState({ stockings: previousStockings });
		}
	};

	handleActiveSort = (sort) => {
		this.setState({ activeSort: sort });
	};

	handleArchivedSort = (sort) => {
		this.setState({ archivedSort: sort });
	};

	loadStockings = async () => {
		const { account } = this.props;
		if (account !== null) {
			const stockings = await StockingService.getStockings(account.accountId);
			this.setState(
				{
					stockings,
					activeColumns: this.getColumns("active", account.currency),
					archivedColumns: this.getColumns("archived", account.currency),
					activeNarrowColumns: this.getNarrowTableColumns("active", account.currency),
					archivedNarrowColumns: this.getNarrowTableColumns("archived", account.currency),
				},
				this.loadStockingBalances
			);
		}
	};

	loadStockingBalances = async () => {
		const { account } = this.props;
		this.state.stockings.forEach(async (stocking) => {
			const balance = await StockingService.getStockingBalance(account.accountId, stocking.stockingId);
			const index = this.state.stockings.findIndex((s) => s.stockingId === stocking.stockingId);
			this.setState((prevState) => {
				const stockings = [...prevState.stockings];
				stockings[index].balance = balance.balance;
				return { stockings };
			});
		});
	};

	async componentDidUpdate(prevProps, prevState) {
		if (prevProps.account !== this.props.account) {
			this.loadStockings();
		}
	}

	async componentDidMount() {
		this.loadStockings();
	}

	render() {
		const { stockings, activeSort, archivedSort, activeColumns, archivedColumns, activeNarrowColumns, archivedNarrowColumns } = this.state;
		const activeStockings = _.orderBy(
			stockings.filter((s) => s.visible === true),
			[activeSort.key],
			[activeSort.order]
		);
		const archivedStockings = _.orderBy(
			stockings.filter((s) => s.visible === false),
			[archivedSort.key],
			[archivedSort.order]
		);

		return (
			<div className="m-3 mb-5">
				<div className="mt-5 text-center">
					<div className="h2 mb-5">Aktive Sparstrümpfe</div>
					{activeStockings.length > 0 && (
						<>
							<div className="d-none d-md-block">
								<Table data={activeStockings} columns={activeColumns} sort={activeSort} onSort={this.handleActiveSort} />
							</div>
							<div className="d-block d-md-none">
								<Table data={activeStockings} columns={activeNarrowColumns} sort={activeSort} onSort={this.handleActiveSort} />
							</div>
						</>
					)}
				</div>

				{archivedStockings.length > 0 && (
					<div className="mt-5 text-center">
						<div className="h2 mb-5">Archivierte Sparstrümpfe</div>
						<div className="d-none d-md-block">
							{<Table data={archivedStockings} columns={archivedColumns} sort={archivedSort} onSort={this.handleArchivedSort} />}
						</div>
						<div className="d-block d-md-none">
							{<Table data={archivedStockings} columns={archivedNarrowColumns} sort={archivedSort} onSort={this.handleArchivedSort} />}
						</div>
					</div>
				)}
			</div>
		);
	}
}

export default StockingManagement;
