import React from "react";
import { Link } from "react-router-dom";
import { TrashFill } from "react-bootstrap-icons";
import Form from "../common/forms/form";
import Input from "../common/forms/input";
import Radio from "../common/forms/radio";
import radioItems from "../../conf/radioItems.json";
import Validation from "../../services/validation";
import Stocking from "../../services/stockingService";
import ApplicationContext from "../../services/applicationContext";
import Banner from "../common/Banner";
import errorMessages from "../../conf/errors.json";
import ColorPicker from "../common/forms/colorPicker";
import ResponsiveButton from "../common/forms/responsiveButton";

class StockingForm extends Form {
	static contextType = ApplicationContext;
	presets = ["#23ac4f", "#678aaa", "#ac722d", "#f8e6a6", "#ffdb5b", "#c1967a", "#78b3dc", "#e59e2e", "#cccccc", "#00ff00", "#ff0000", "#0000ff"];
	focusRef = React.createRef();

	state = {
		// @Override
		data: {
			name: "",
			type: "automatic",
			stockingId: null,
			color: "",
		},
		errors: {},
	};

	// @Override
	getSchema = () => Validation.schemas.stockingFormSchema;

	// @Override
	doSubmit = () => {
		const { accountId, onSubmitted } = this.props;
		const { data } = this.state;

		if (data.stockingId) {
			this.doSubmitUpdate(accountId, data, onSubmitted);
		} else {
			this.doSubmitCreation(accountId, data, onSubmitted);
		}
	};

	doSubmitUpdate = async (accountId, data, onSubmitted) => {
		try {
			const changes = {};
			if (this.props.data.name !== data.name) changes.name = data.name;
			if (this.props.data.type !== data.type) changes.type = data.type;
			if (this.props.data.color !== data.color) changes.color = data.color;
			if (Object.keys(changes).length > 0) {
				const stocking = await Stocking.updateStocking(accountId, data.stockingId, changes);
				onSubmitted("update", stocking);
			} else {
				onSubmitted("update", null);
			}
		} catch (error) {
			console.log("error", error);
			Stocking.handleExpectedErrors(error, this);
		}
	};

	doSubmitCreation = async (accountId, data, onSubmitted) => {
		try {
			const stocking = await Stocking.createStocking(accountId, data);
			onSubmitted(stocking);
		} catch (error) {
			console.log("error", error);
			Stocking.handleExpectedErrors(error, this);
		}
	};

	handleDeleteClick = (event) => {
		event.preventDefault();
		const confirmationText =
			"Soll dieser Sparstrumpf wirklich gelöscht werden? Alle Daten dieses Sparstrumpfes inklusive Transaktionen und wiederkehrender Buchungen werden unwiderruflich gelöscht!";
		const confirmationButtons = [
			{ label: "Unwiderruflich löschen", value: "delete", color: "danger", hint: "Löscht diesen Sparstrumpf unwiderruflich." },
			{ label: "Abbrechen", value: "cancel", color: "secondary", hint: "Bricht das Löschen ab." },
		];
		this.context.showDialogModal("Achtung", confirmationText, confirmationButtons, "md", this.handleDialogResult);
	};

	handleDialogResult = (value) => {
		this.context.hideDialogModal();
		if (value === "delete") this.deleteStocking();
	};

	deleteStocking = async () => {
		const { accountId, onSubmitted } = this.props;
		const { stockingId } = this.state.data;

		try {
			await Stocking.deleteStocking(accountId, stockingId);
			onSubmitted("delete", { stockingId });
		} catch (error) {
			console.log("error", error);
			Stocking.handleExpectedErrors(error, this);
		}
	};

	handleArchiveClick = async (event) => {
		event.preventDefault();
		const { accountId, onSubmitted } = this.props;
		const { stockingId } = this.state.data;
		try {
			const stocking = await Stocking.updateStocking(accountId, stockingId, { visible: false });
			onSubmitted("update", stocking);
		} catch (error) {
			console.log("error", error);
			Stocking.handleExpectedErrors(error, this);
		}
	};

	componentDidMount() {
		const { data } = this.props;
		if (data) {
			this.setState({ data });
		}
	}

	// @Override
	render() {
		const { errorMessage, errors, data } = this.state;
		const { buttonLabel, deletable } = this.props;
		const hasLicense = this.context.getLicenseType();

		return (
			<>
				{!this.context.getLicenseType() && (
					<Banner>
						Bitte verlängere <Link to="/abo">Dein Abo</Link> um diese Funktion zu nutzen.
					</Banner>
				)}
				<div className="m-2">
					<form onSubmit={this.handleSubmit}>
						<div className="row">
							<div className="col">
								<Input
									reference={this.focusRef}
									label="Sparstrumpf-Name"
									placeholder="z.B. Urlaub"
									name="name"
									error={errors.name}
									type="text"
									value={data.name}
									onChange={this.handleChange}
								/>
							</div>
						</div>
						<div className="row">
							<div className="col">
								<Radio
									label="Sparstrumpf-Typ"
									name="type"
									isWithinModal={true}
									error={errors.type}
									value={data.type}
									items={radioItems.stockingTypes}
									onChange={this.handleChange}
								/>
							</div>
							<div className="col">
								<ColorPicker
									label="Farbe"
									name="color"
									error={errors.color}
									value={data.color || "#23ac4f"}
									presets={this.presets}
									onChange={this.handleChange}
								/>
							</div>
						</div>
						{errorMessage && (
							<div className="alert alert-danger" role="alert">
								{errorMessage}
							</div>
						)}
						<div className="d-flex justify-content-between">
							<ResponsiveButton
								label={buttonLabel}
								color="primary"
								hint={buttonLabel === "Speichern" ? "Speichert die Änderungen an diesem Sparstrumpf." : "Legt diesen Sparstrumpf an."}
								hasLicense={hasLicense}
								disabled={this.validate()}
								disabledHint="Bitte korrigiere die Fehler im Formular"
							/>

							{data && data.stockingId && (
								<div className="d-flex">
									<ResponsiveButton
										label="Archivieren"
										color="warning"
										hint="Archiviert den Sparstrumpf. Du kannst ihn später wieder herstellen."
										hasLicense={hasLicense}
										disabled={!deletable}
										disabledHint={errorMessages["stocking_balance_not_zero"]}
										className="me-2"
										onClick={this.handleArchiveClick}
									/>

									<ResponsiveButton
										label="Löschen"
										labelIcon={TrashFill}
										iconBreakpoint="sm"
										color="danger"
										hint="Löscht den Sparstrumpf unwiderruflich."
										hasLicense={hasLicense}
										disabled={!deletable}
										disabledHint={errorMessages["stocking_balance_not_zero"]}
										onClick={this.handleDeleteClick}
									/>
								</div>
							)}
						</div>
					</form>
				</div>
			</>
		);
	}
}

export default StockingForm;
