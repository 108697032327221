import React, { Component } from "react";
import { Square, CheckSquare, XSquare, ArrowLeftSquare, ArrowRightSquare } from "react-bootstrap-icons";
import ApplicationContext from "../../services/applicationContext";
import errorMessages from "../../conf/errors.json";

class TransactionActions extends Component {
	static contextType = ApplicationContext;
	deleteRecurringOrTransactionText =
		"Diese Buchung ist Bestandteil einer wiederkehrenden Buchungsregel. Möchtest Du die Einzelbuchung oder diese und alle nachfolgenden Buchungen löschen?";
	deleteRecurringOrTransactionButtons = [
		{ label: "Einzelbuchung", value: "transaction", color: "primary", hint: "Löscht nur die ausgewählte Buchung." },
		{ label: "Alle folgenden", value: "recurring", color: "warning", hint: "Löscht diese und alle folgenden Buchungen dieser Buchungsregel." },
	];
	iconStyle = { marginTop: "-2px" };

	getButtonClasses = (marginEnd, textColor, disabled, hasLicense) => {
		return "me-" + marginEnd + " " + (disabled || !hasLicense ? "text-secondary opacity-50" : textColor) + (!hasLicense ? " license-expired" : "");
	};

	getButtonStyles = (disabled) => {
		return !disabled ? { cursor: "pointer" } : null;
	};

	getSize = () => {
		const { small } = this.props;
		if (small) return 12;
		else return 16;
	};

	handleDeleteClick = () => {
		const { deleteAcknowledgeDialog, onDelete } = this.props;
		if (deleteAcknowledgeDialog) {
			this.context.showDialogModal(
				"Achtung",
				this.deleteRecurringOrTransactionText,
				this.deleteRecurringOrTransactionButtons,
				"md",
				this.handleDialogResult
			);
		} else {
			onDelete(false);
		}
	};

	handleDialogResult = (value) => {
		const { onDelete } = this.props;
		this.context.hideDialogModal();
		onDelete(value === "recurring");
	};

	render() {
		const { processed, onProcessed, onNotProcessed, onMoveBackward, onMoveForward, onDelete } = this.props;
		const hasLicense = this.context.getLicenseType();
		const buttonHint = !hasLicense ? errorMessages["license_expired"] : "";

		return (
			<div className="d-flex align-items-baseline ms-3 text-nowrap text-center" style={{ marginBottom: "2px" }}>
				{processed !== undefined && (
					<div title={buttonHint || "Zahlungsstatus"} style={this.iconStyle}>
						{processed && (
							<CheckSquare
								className={this.getButtonClasses(4, "text-primary", onProcessed === null, hasLicense)}
								size={this.getSize()}
								style={this.getButtonStyles(onProcessed === null)}
								onClick={hasLicense ? onNotProcessed : null}
							/>
						)}
						{!processed && (
							<Square
								className={this.getButtonClasses(4, "text-primary", onProcessed === null, hasLicense)}
								size={this.getSize()}
								style={this.getButtonStyles(onProcessed === null)}
								onClick={hasLicense ? onProcessed : null}
							/>
						)}
					</div>
				)}

				{onMoveBackward !== undefined && (
					<div title={buttonHint || "In den Vormonat verschieben"} style={this.iconStyle}>
						<ArrowLeftSquare
							className={this.getButtonClasses(2, "text-primary", processed || onMoveBackward === null, hasLicense)}
							size={this.getSize()}
							style={this.getButtonStyles(processed || onMoveBackward === null)}
							onClick={!processed && hasLicense ? onMoveBackward : null}
						/>
					</div>
				)}

				{onMoveForward !== undefined && (
					<div title={buttonHint || "In den Folgemonat verschieben"} style={this.iconStyle}>
						<ArrowRightSquare
							className={this.getButtonClasses(2, "text-primary", processed || onMoveBackward === null, hasLicense)}
							size={this.getSize()}
							style={this.getButtonStyles(processed || onMoveBackward === null)}
							onClick={!processed && hasLicense ? onMoveForward : null}
						/>
					</div>
				)}

				{onDelete && (
					<div title={buttonHint || "Buchung löschen"} style={this.iconStyle}>
						<XSquare
							className={this.getButtonClasses(1, "text-danger", processed, hasLicense)}
							size={this.getSize()}
							style={this.getButtonStyles(processed)}
							onClick={!processed && hasLicense ? this.handleDeleteClick : null}
						/>
					</div>
				)}
			</div>
		);
	}
}

export default TransactionActions;
