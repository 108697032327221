import React, { useState } from "react";
import errorMessages from "../../../conf/errors.json";
import TransactionPopover from "../../protected/transactionPopover";

const ResponsiveButton = (props) => {
	const { hasLicense, disabled, disabledHint, hint, color, label, value, onClick, labelIcon: LabelIcon, iconBreakpoint, className } = props;

	const [popoverOpen, setPopoverOpen] = useState(false);
	const buttonRef = React.createRef();

	const buttonDisabled = !hasLicense || disabled;
	const buttonHint = !hasLicense ? errorMessages["license_expired"] : disabled ? disabledHint : hint;
	const cursor = !hasLicense ? "not-allowed" : disabled ? "default" : "pointer";

	return (
		<div title={buttonHint} style={{ cursor }} onClick={() => (buttonDisabled ? setPopoverOpen(!popoverOpen) : null)}>
			<button
				ref={buttonRef}
				className={"btn btn-" + color + (className ? " " + className : "")}
				disabled={buttonDisabled}
				onClick={onClick}
				value={value}
			>
				{!LabelIcon && !iconBreakpoint && label}
				{LabelIcon && !iconBreakpoint && <LabelIcon size="20" style={{ marginTop: -2 }} />}
				{LabelIcon && iconBreakpoint && (
					<>
						<div className={"d-block d-" + iconBreakpoint + "-none"}>
							<LabelIcon size="20" style={{ marginTop: -2 }} />
						</div>
						<div className={"d-none d-" + iconBreakpoint + "-block"}>{label}</div>
					</>
				)}
			</button>
			<TransactionPopover
				isOpen={popoverOpen}
				target={buttonRef}
				onClose={() => setPopoverOpen(false)}
				placement="top"
				title={"Nicht möglich"}
				isWithinModal={true}
				render={() => <div style={{ width: "200px" }}>{buttonHint}</div>}
			/>
		</div>
	);
};

export default ResponsiveButton;
