import React, { Component } from "react";
import { Route, Switch, withRouter, Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Contact from "./components/contact";
import Footer from "./components/footer";
import Home from "./components/home";
import Imprint from "./components/imprint";
import Login from "./components/login";
import Main from "./components/protected/main";
import Reports from "./components/protected/reports";
import NavBarWithRouter from "./components/navBar";
import Privacy from "./components/privacy";
import Register from "./components/register";
import ScrollToTop from "./components/scrollToTop";
import ThankYou from "./components/common/thankYou";
import Verification from "./components/verification";
import http from "./services/httpService";
import auth from "./services/authService";
import logger from "./services/loggingService";
import Validation from "./services/validation";
import ApplicationContext from "./services/applicationContext";
import "react-toastify/dist/ReactToastify.css";
import Profil from "./components/protected/profil";
import ProtectedRoute from "./components/common/protectedRoute";
import FormModal from "./components/common/formModal";
import DialogButtons from "./components/common/forms/dialogButtons";
import AccountService from "./services/accountService";
import AccountForm from "./components/forms/accountForm";
import ForgotPassword from "./components/forgotPassword";
import ResetPassword from "./components/resetPassword";
import Banner from "./components/common/Banner";
import StockingManagement from "./components/protected/stockingManagement";

Validation.init({});

class AppComponent extends Component {
	getLicenseType = () => {
		const { user } = this.state;
		if (user) {
			if (!user.license || new Date(user.license.validTo) < new Date()) return null;
			return user.license.type;
		} else {
			return "unknown";
		}
	};

	hideModal = () => {
		const modal = { ...this.state.modal };
		modal.isOpen = false;
		this.setState({ modal });
	};

	hideDialogModal = () => {
		const dialogModal = { ...this.state.dialogModal };
		dialogModal.isOpen = false;
		this.setState({ dialogModal });
	};

	showFormModal = (title, bodyComponent, properties, size, onSubmitted, className) => {
		const modal = { ...this.state.modal };
		modal.isOpen = true;
		modal.title = title;
		modal.bodyComponent = bodyComponent;
		modal.bodyText = null;
		modal.footerComponent = null;
		modal.size = size;
		modal.properties = properties;
		modal.onSubmitted = onSubmitted;
		modal.className = className;
		modal.verticallyCentered = false;
		this.setState({ modal });
	};

	showDialogModal = (title, text, buttons, size, onSubmitted, className) => {
		const dialogModal = { ...this.state.dialogModal };
		dialogModal.isOpen = true;
		dialogModal.title = title;
		dialogModal.bodyComponent = null;
		dialogModal.bodyText = text;
		dialogModal.footerComponent = DialogButtons;
		dialogModal.size = size;
		dialogModal.properties = { buttons };
		dialogModal.onSubmitted = onSubmitted;
		dialogModal.className = className;
		dialogModal.verticallyCentered = true;
		this.setState({ dialogModal });
	};

	handleLogin = (user) => {
		this.setState({ user }, this.loadAccounts);
	};

	handleLogout = () => {
		this.setState({ user: null, accounts: null, selectedAccountIndex: null });
	};

	handleForbidden = () => {
		if (this.state.user) {
			console.log("onForbidden Handler -> logOut -> forward to Login Screen");
			auth.logout(this); // auth will call context.handleLogout on this component instead of a real context
			this.props.history.push("/login");
		}
	};

	handleAccountCreated = (account) => {
		this.hideModal();
		const accounts = [...this.state.accounts];
		accounts.push(account);
		this.setState({ accounts, selectedAccountIndex: accounts.length - 1 });
	};

	handleAccountInitialized = (account) => {
		const accounts = [...this.state.accounts];
		const idx = accounts.findIndex((a) => a.accountId === account.accountId);
		const newAccount = { ...accounts[idx] };
		newAccount.openBalance = 0;
		accounts[idx] = newAccount;
		this.setState({ accounts });
	};

	showAccountForm = () => {
		this.showFormModal("Basiskonto anlegen", AccountForm, {}, "lg", this.handleAccountCreated);
	};

	configure = async () => {
		const { data: configuration } = await http.get("configuration/web");
		this.setState({ configuration });
		Validation.init(configuration);
	};

	loadUser = async () => {
		const user = await auth.getCurrentUser();
		this.setState({ user });
		if (user) {
			await this.loadAccounts();
			if (this.props.location.pathname === "/") this.props.history.push("/app/start");
		}
	};

	loadAccounts = async () => {
		const accounts = await AccountService.getAccounts();
		this.setState({ accounts, selectedAccountIndex: 0 });
		if (accounts && accounts.length === 0) {
			this.showAccountForm();
		}
	};

	async componentDidMount() {
		http.setOnForbidden(this.handleForbidden);
		await Promise.all([this.configure(), this.loadUser()]);
		logger.logMessage("Application Loaded");
	}

	state = {
		configuration: {},
		modal: {
			isOpen: false,
			onClose: this.hideModal,
		},
		dialogModal: {
			isOpen: false,
			onClose: this.hideDialogModal,
		},
		accounts: null,
		selectedAccountIndex: null,
	};

	render() {
		const appContext = {
			configuration: this.state.configuration,
			user: this.state.user,
			getLicenseType: this.getLicenseType,
			handleLogin: this.handleLogin,
			handleLogout: this.handleLogout,
			hideModal: this.hideModal,
			hideDialogModal: this.hideDialogModal,
			showFormModal: this.showFormModal,
			showDialogModal: this.showDialogModal,
		};
		const { modal, dialogModal, accounts, selectedAccountIndex } = this.state;
		const account = accounts && accounts[selectedAccountIndex];

		return (
			<ApplicationContext.Provider value={appContext}>
				{(modal.bodyComponent || modal.bodyText) && <FormModal {...modal} />}
				{(dialogModal.bodyComponent || dialogModal.bodyText) && <FormModal {...dialogModal} />}
				<div className="d-flex flex-column min-vh-100">
					<NavBarWithRouter />
					{!this.getLicenseType() && (
						<Banner>
							Deine Lizenz ist abgelaufen! <Link to="/abo">Hier</Link> kannst Du Dein Abo verlängern.
						</Banner>
					)}
					<main className="container flex-fill">
						<ToastContainer />
						<ScrollToTop />
						<Switch>
							<ProtectedRoute
								path="/app/start"
								render={(props) => (
									<Main
										{...props}
										account={account}
										onAccountInitialized={this.handleAccountInitialized}
										handleAccountCreate={this.showAccountForm}
									/>
								)}
							/>
							<ProtectedRoute path="/app/stockings" render={(props) => <StockingManagement {...props} account={account} />} />
							<ProtectedRoute exact path="/app/reports" render={(props) => <Reports {...props} account={account} />} />
							<ProtectedRoute exact path="/app/reports/:report" render={(props) => <Reports {...props} account={account} />} />
							<ProtectedRoute exact path="/app/reports/:report/:param" render={(props) => <Reports {...props} account={account} />} />
							<ProtectedRoute path="/app/user/profil" component={Profil} />
							<Route path="/impressum" component={Imprint} />
							<Route path="/datenschutz" component={Privacy} />
							<Route path="/kontakt" component={Contact} />
							<Route path="/registrieren/danke" render={(props) => <ThankYou {...props} for="register" />} />
							<Route path="/registrieren" component={Register} />
							<Route exact path="/aktivieren/danke" render={(props) => <ThankYou {...props} for="verify" />} />
							<Route exact path="/aktivieren/:token" component={Verification} />
							<Route path="/login" component={Login} />
							<Route exact path="/vergessen/danke" render={(props) => <ThankYou {...props} for="forgot" />} />
							<Route exact path="/vergessen/erfolgreich" render={(props) => <ThankYou {...props} for="resetted" />} />
							<Route exact path="/vergessen/:token" component={ResetPassword} />
							<Route path="/vergessen" component={ForgotPassword} />
							<Route path="/" component={Home} />
						</Switch>
					</main>
					<Footer />
				</div>
			</ApplicationContext.Provider>
		);
	}
}

const App = withRouter(AppComponent);

export default App;
