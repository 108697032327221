import React from "react";
import ResponsiveButton from "./responsiveButton";

const DialogButtons = (props) => {
	const { buttons, onSubmitted } = props;
	return (
		<div className="d-flex justify-content-end">
			{buttons.map((button, idx) => (
				<ResponsiveButton
					key={idx}
					label={button.label}
					color={button.color ? button.color : "primary"}
					hint={button.hint}
					hasLicense={true}
					disabled={false}
					className="me-2"
					onClick={() => onSubmitted(button.value)}
				/>
			))}
		</div>
	);
};

export default DialogButtons;
