import React from "react";
import { Link, withRouter } from "react-router-dom";
import Form from "../common/forms/form";
import Input from "../common/forms/input";
import Validation from "../../services/validation";
import ApplicationContext from "../../services/applicationContext";
import Auth from "../../services/authService";
import ResponsiveButton from "../common/forms/responsiveButton";

class LoginFormComponent extends Form {
	static contextType = ApplicationContext;
	state = {
		// @Override
		data: {
			username: "",
			password: "",
		},
		errors: {},
	};

	// @Override
	getSchema = () => Validation.schemas.loginFormSchema;

	// @Override
	doSubmit = async () => {
		const { location } = this.props;
		try {
			await Auth.login(this.context, this.state.data);
			// forward to either the last location before login (by ProctectedRoute) or
			// to the default app start page
			this.props.history.push(location.state ? location.state.from.pathname : "/app/start");
		} catch (ex) {
			Auth.handleExpectedErrors(ex, this);
		}
	};

	// @Override
	render() {
		const { errorMessage, errors, data } = this.state;
		return (
			<div>
				<form onSubmit={this.handleSubmit}>
					<Input
						label="E-Mail"
						name="username"
						error={errors.username}
						type="text"
						placeholder=""
						value={data.username}
						onChange={this.handleChange}
					/>
					<Input
						label="Passwort"
						name="password"
						error={errors.password}
						type="password"
						placeholder=""
						value={data.password}
						onChange={this.handleChange}
					/>
					<div className="d-flex w-100 justify-content-end" style={{ marginTop: "-10px" }}>
						<Link className="me-1" to="/vergessen">
							Passwort vergessen?
						</Link>
					</div>

					{errorMessage && (
						<div className="alert alert-danger" role="alert">
							{errorMessage}
						</div>
					)}

					<ResponsiveButton
						label="Login"
						color="primary"
						hint="Ermöglicht registrierten Nutzern den Zugang zu Mein Sparstrumpf."
						hasLicense={true}
						disabled={this.validate()}
						disabledHint="Bitte fülle zunächst das Formular aus."
					/>

					<div className="form-text mt-2">
						Wir verpflichten uns zum Schutz Deiner Daten. Details erfährst Du unter <Link to="/datenschutz">Datenschutz</Link>.
					</div>
				</form>
			</div>
		);
	}
}

const LoginForm = withRouter(LoginFormComponent);

export default LoginForm;
