import React from "react";
import { Link, withRouter } from "react-router-dom";
import Form from "../common/forms/form";
import Input from "../common/forms/input";
import Validation from "../../services/validation";
import Account from "../../services/accountService";
import { toast } from "react-toastify";
import ApplicationContext from "../../services/applicationContext";
import Select from "../common/forms/select";
import ResponsiveButton from "../common/forms/responsiveButton";

class AccountFormComponent extends Form {
	static contextType = ApplicationContext;

	supportedCurrencies = [
		{ value: "EUR", label: "Euro (EUR)" },
		{ value: "GBP", label: "Britisches Pfund (GBP)" },
		{ value: "DKK", label: "Dänische Kronen (DKK)" },
		{ value: "NOK", label: "Norwegische Kronen (NOK)" },
		{ value: "PLN", label: "Polnischer Zloty (PLN)" },
		{ value: "SEK", label: "Schwedische Kronen (SEK)" },
		{ value: "CHF", label: "Schweizer Franken (CHF)" },
		{ value: "USD", label: "US-Dollar (USD)" },
	];

	state = {
		// @Override
		data: {
			name: "",
			currency: "EUR",
			iban: "",
			owner: "",
			balance: "",
		},
		errors: {},
	};

	// @Override
	getSchema = () => Validation.schemas.accountFormSchema;

	// @Override
	doSubmit = async () => {
		const { data } = this.state;
		const { onSubmitted } = this.props;

		const requestData = { ...data, balance: Number(data.balance) };
		try {
			const account = await Account.createAccount(requestData);
			toast.success("Dein Basiskonto wurde erfolgreich angelegt.");
			onSubmitted(account);
		} catch (ex) {
			console.log(ex);
			Account.handleExpectedErrors(ex, this);
		}
	};

	// @Override
	render() {
		const { errorMessage, errors, data } = this.state;
		const hasLicense = this.context.getLicenseType();

		return (
			<form onSubmit={this.handleSubmit}>
				<div className="row">
					<div className="col-lg">
						<Input
							label="Name des Kontos"
							placeholder="z.B. Tagesgeld"
							name="name"
							error={errors.name}
							type="text"
							value={data.name}
							onChange={this.handleChange}
						/>
						<Select
							label="Währung"
							placeholder="z.B. EUR"
							name="currency"
							error={errors.currency}
							type="text"
							value={data.currency}
							options={this.supportedCurrencies}
							onChange={this.handleChange}
						/>
						<Input
							label="Aktueller Kontostand"
							notice="Um immer einen Abgleich zwischen der Summe Deiner Sparstrümpfe und Deinem Basiskonto machen zu können, sag uns bitte Deinen Kontostand. Im nächsten Schritt kannst Du Dein vorhandenes Guthaben dann auf neue Sparstrümpfe aufteilen."
							name="balance"
							error={errors.balance}
							type="number"
							step="0.01"
							value={data.balance}
							onChange={this.handleChange}
						/>
					</div>
					<div className="col-lg">
						<Input
							label="IBAN"
							name="iban"
							placeholder="DE28 1234 5678 0000 1000 34"
							notice=" Optional. Damit können wir Dir QR-Codes für Überweisungen anzeigen, wenn Du das möchtest."
							error={errors.iban}
							type="text"
							value={data.iban}
							onChange={this.handleChange}
						/>
						<Input
							label="Konto-Inhaber"
							placeholder="Max Mustermann"
							notice=" Optional. Damit können wir Dir QR-Codes für Überweisungen anzeigen, wenn Du das möchtest."
							name="owner"
							error={errors.owner}
							type="text"
							value={data.owner}
							onChange={this.handleChange}
						/>
					</div>
					{errorMessage && (
						<div className="alert alert-danger" role="alert">
							{errorMessage}
						</div>
					)}

					<ResponsiveButton
						label="Anlegen"
						color="primary"
						hint="Erstellt dein (virtuelles) Basis-Konto für die Arbeit von Mein Sparstrumpf."
						hasLicense={hasLicense}
						disabled={this.validate()}
						disabledHint="Bitte fülle zunächst das Formular aus."
						className="w-100"
					/>

					<div className="form-text mt-2">
						Wir verpflichten uns zum Schutz Deiner Daten. Details erfährst Du unter <Link to="/datenschutz">Datenschutz</Link>.
					</div>
				</div>
			</form>
		);
	}
}

const AccountForm = withRouter(AccountFormComponent);

export default AccountForm;
